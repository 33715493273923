import React, { useReducer, useEffect, useState } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import app from '../feathers';
import { checkExpiredToken } from '../util/token';
import { sortByKey } from '../util/sort';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import useWalletError from '../hooks/useWalletError';
import TMPaper from '../lib/Paper/TMPaper';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import useIntl from '../hooks/useIntl';
import BrowserSupport from './BrowserSupport';
import useSentry from '../hooks/useSentry';
import useDrawer from '../hooks/useDrawer';

const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '80px',
    width: '40vw',
    boxShadow: 'none',
  },
  notice: {
    width: '40vw',
    '& iframe': {
      border: 0,
      width: '100%',
      height: '100vh',
    }
  }
});

const SetLocalStorageVars = (props) => {

  const { translate } = useIntl();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showPassword, setShowPassword] = useState(false);
  const onWalletError = useWalletError();
  const classes = useStyles();
  const logSentry = useSentry();
  const { openDrawer } = useDrawer();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    componentDidMount()
  }, [])

  // DIALOG HANDLERS
  //////////////////
  const setAskEmail = (askEmail = false) => {
    dispatch({
      payload: { askEmail }
    })
  };

  // FORM HANDLERS
  ////////////////
  const handleChange = (event) => {
    let { localStorageVars } = state;
    localStorageVars[event.target.name] = event.target.value;
    dispatch({ payload: { localStorageVars, error: event.target.name === 'email' ? '' : state.error } });
  };


  const useAnotherEmail = async () => {
    window.localStorage.removeItem('tmining_wallet');
    window.location.reload()
  }

  // email as param comes from componentDidMount
  // email from localStorageVars comes from the user filling in the form
  const checkOrganization = async (email) => {
    let { localStorageVars } = state;
    if (!localStorageVars.email && !email) { 
      return;
    }

    // callin g this method from onBlur, 'email' will have type 'Class'
    email = typeof email === 'string' ? email : localStorageVars.email;

    let result = await app.service('users').find({ 
      query: { 
        email: localStorageVars.email && localStorageVars.email.toLowerCase().trim(),
        isLogin: true,
      },
    })

    if (result.total === 0) {
      dispatch({ payload: { 
        error: translate('wallet.email.notFound'), 
        progress: false, 
        userOrganizations: [] } 
      })
    } else {
      if (result.total === 1) {
        // Set organization to the user's organization
        // but only if we're on a dedicated webapp, OR we aren't and it's not a carrier logging in.
        if (result.isLimitedToOrgId === true || result.data[0].organization.role !== 'carrier') {
          handleChange({ target: { name: 'organization', value: result.data[0].organization.address } })
        }
      }

      dispatch({ 
        payload: {
          userOrganizations: result.data
            .map(({ organization: { name, address, role } }) => {
              // Check if a carrier is trying to log in into the 'central' webapp, instead of his own dedicated app
              let isDisabled = false;
              if (role === 'carrier' && result.isLimitedToOrgId === false && process.env.REACT_APP_NODE_ENV === 'production') {
                name += " -- Use your dedicated SCR app.";
                isDisabled = true;
              }
              return { name, address, isDisabled }
            }) 
            .sort((o1, o2) => { 
              if (o1.name.toLowerCase() < o2.name.toLowerCase()) return -1
              if (o1.name.toLowerCase() > o2.name.toLowerCase()) return 1
              return  0
            })
        }
      })
    }
  }

  // ACTION METHODS
  /////////////////
  const handleSubmit = async (e) => {

    e.preventDefault()
    let unlockParams = { password: state.localStorageVars.password };
    let user, organization, url;

    try {

      const organization_address = state.localStorageVars.organization
      if (state.askEmail && !organization_address) {
        // state.userOrganizations.length > 0 means there ARE organizations to choose from. 
        // If not, it means the emailaddress is unknown, so that error must remain.
        if (state.userOrganizations.length > 0) {
          throw new Error(translate('wallet.organization.select'))
        } else {
          return;
        }
      }

      dispatch({ payload: { progress: true } })

      organization = await app.service('organizations').get(organization_address, { skipIncludes: true });
      url = organization.wallet_url === null ? undefined : organization.wallet_url;

      if (state.askEmail) {
        user = await app.service('users').find({ query: { email: state.localStorageVars.email && state.localStorageVars.email.toLowerCase().trim() } })

        if (user.total === 0) {
          throw new Error(translate('wallet.email.notFound'))
        } else {
          if (state.askEmail && !organization_address) {
            throw new Error(translate('wallet.organization.select'))
          }

          user = user.data.find(userData => {
            return userData.organization_address === organization_address;
          });
          if (user === undefined) {
            throw new Error("Could not determine your organization. Please sign in again using the 'Use another email address' button.")
          }
        }

        try {
          await window.walletApi._storeWalletInfo({
            id: user.id,
            email: user.email,
            type: 'company',
            url: url,
            address: organization_address
          });
          // console.log("_storeWalletInfo OK")

        } catch (e) {
          onWalletError(e)
          dispatch({ payload: { askEmail: true, progress: false } })

          return
        }

        unlockParams.id = user.id;
        unlockParams.type = user.isAdmin ? 'admin' : 'user';

      } else {
        // if wallet info is present, use it
        if (state.walletInfo) {
          user = await app.service('users').find({ query: { email: state.walletInfo.email && state.walletInfo.email.toLowerCase().trim() } });
          if (user.total === 0) {
            throw new Error(translate('wallet.email.notFound'))
          } else {
            user = user.data.find(userData => {
              return userData.organization_address === organization_address // state.walletInfo.address;
            });
            if (user === undefined) {
              throw new Error("Could not determine your organization. Please sign in again using the 'Use another email address' button.")
            }
          }
          organization = await app.service('organizations').get(user.organization_address, { skipIncludes: true });
          
          try {
            await window.walletApi._storeWalletInfo({
              id: user.id,
              email: user.email,
              type: 'company',
              url: organization.wallet_url === null ? undefined : organization.wallet_url,
              address: organization_address
            });

          } catch (e) {
            onWalletError(e)
            dispatch({ payload: { askEmail: true, progress: false } })
            return
          }

          unlockParams.id = user.id;
          unlockParams.type = user.isAdmin ? 'admin' : 'user';
        }
      }

      try {
        console.log("unlocking...")
        await window.walletApi.unlockAccount(unlockParams);
        console.log("unlocked!")
      } catch (e) {
        logSentry(e);
        let addUserSuccess = false;
        if ((/user with id \d+ not found/i).test(e.message) || 
            (/Cannot read property '\d+' of undefined/i).test(e.message)) 
        {
          console.log("Admin was not added to the wallet")
          // this might be an older admin that was never added to the wallet as a user.
          // so we will unlock the wallet and add the user with the password he just entered
          unlockParams.id = -1;
          try {
            await window.walletApi.unlockAccount(unlockParams, url);
            // unlock sets the token - save the token from walletEntry here
            const { token } = window.walletApi._getWalletInfo();
            const govappToken = await app.service('sign').find();
            // Add unfound admin account to wallet.
            // this overwrites the localStorage, that's why we save the token before this
            await window.walletApi.addUserAccount(
              { id: user.id, password: unlockParams.password }, 
              url,
              govappToken.token
            );
            // get the new data that addUserAccount set in local storage
            let walletEntry = window.walletApi._getWalletInfo();
            // add to it
            walletEntry.email = user.email;
            walletEntry.address = organization.address;
            walletEntry.token = token; // this ensures the user continues to log in 
            // and save it to continue logging in
            await window.walletApi._storeWalletInfo(walletEntry);
            addUserSuccess = true;
          } catch (error) {
            logSentry("errored:", error);
            // check the original error and translate if necessary
            if ((/Cannot read property '\d+' of undefined/i).test(e.message)) {
              e.message = `Could not find your user record in your ID wallet.`
            }
            e.message += `. ${error.message}.`;
          }
        }
        if (!addUserSuccess) {
          // onWalletError(e)
          let eMessage = e.message;
          if (e.message === "Gateway Timeout") {
            eMessage = "Could not reach the API. Please contact your admin user."
          }
          if (e.message === "invalid password") {
            eMessage = "Your password is invalid."
          }
          if (e.message.includes("the network is offline")) {
            eMessage = `
              Login failed. We cannot reach the ID wallet of your organization. This is required to securely access the application. 
              Please refer to the help information on the right side of the screen for tips on how to solve this, or contact the support team.`
          }
          dispatch({ payload: { askEmail: true, error: eMessage, progress: false } })
          openDrawer('wallet.html');
          return
        }
      }

      // For Heap analytics:
      if(window.heap) {
        window.heap.identify(user.id);
      }
      
      // console.log("Wallet info successfully stored")
      window.location.reload()
    } catch (err) {
      logSentry(err);
      dispatch({ payload: { error: err.message, progress: false } })
    }
  };

  // LIFECYCLE METHODS
  ////////////////////
  const componentDidMount = async () => {
    try {
      const walletInfo = await window.walletApi._getWalletInfo()
      if (!walletInfo) {
        setAskEmail(true);
      } else {
        if (checkExpiredToken(walletInfo.token)) {
          if (walletInfo.email === undefined) {
            setAskEmail(true);
          } else {
            // prompt for password only
            setAskEmail(false);
            // set the email address in the form and state
            handleChange({ target: { name: 'email', value: walletInfo.email } })
            dispatch({ payload: { walletInfo } });
            await checkOrganization(walletInfo.email);
          }
        }
      }
    } catch (err) {
      if (err.message.includes("not defined")) {
        // prompt for email and password
        setAskEmail(true);
      } else {
        logSentry("componentDidMount", err)
      }
    }
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div style={{display:'flex', width: '100vw', height: '100vh', backgroundColor: '#fff'}}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <BrowserSupport />
        <Paper classes={{ root: classes.notice }} key="notice" id="notice">
          <iframe title='noticeframe' scrolling="no" id="noticeframe" src="https://www.securecontainerrelease.com/iframelogin" />
        </Paper>
        <TMPaper
          classes={{ root: classes.root }}
          key="dialog"
          title={translate('wallet.login.title')}
          logoPath="https://images.squarespace-cdn.com/content/5a4c923580bd5e51e5536643/1514976433052-Y1UEVWA3RSFW65CXGIM4/logo-t-mining-hor.png"
        >
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box display="flex" flexDirection="column">
              <React.Fragment>
                <Box mb="2rem">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="email">{translate('wallet.email.textField')}</InputLabel>
                    <Input required onChange={handleChange} onBlur={checkOrganization} value={state.localStorageVars.email} disabled={!state.askEmail} id="email" type="email" name="email" aria-describedby="email-helper" />
                    <FormHelperText id="email-helper">{translate('wallet.email')}</FormHelperText>
                  </FormControl>
                </Box>
                {state.userOrganizations.length > 0 &&
                  <Box mb="2rem" className='organizations'>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="organization">{translate('wallet.organization.textField')}</InputLabel>
                      <Select MenuProps={MenuProps} required value={state.localStorageVars.organization} displayEmpty onChange={handleChange} id="organization" name="organization" aria-describedby="organization-helper">
                        <MenuItem key='empty' disabled value=''>{translate('wallet.organization.textField')}</MenuItem>
                        { 
                          sortByKey(state.userOrganizations, 'name')
                          .map(({ name, address, isDisabled }) => (<MenuItem key={address} disabled={isDisabled} value={address}>{name}</MenuItem>))
                        }
                      </Select>
                      <FormHelperText id="organization-helper">{translate('wallet.organization')}</FormHelperText>
                    </FormControl>
                  </Box>
                }
              </React.Fragment>

              <Box display="flex" mb="2rem">
                <FormControl fullWidth>
                  <InputLabel htmlFor="password">{translate('wallet.password.passwordField')}</InputLabel>
                  <Input required 
                    onChange={handleChange} 
                    id="password"
                    name="password" 
                    type={showPassword ? 'text' : 'password'} 
                    aria-describedby="password-helper-text" 
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="password-helper-text">{translate('wallet.password')}</FormHelperText>
                </FormControl>
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Button variant="contained" color="primary" type="submit" endIcon={state.progress && <CircularProgress size={20} />} disabled={state.progress}>
                  {translate('wallet.login.button')}
                </Button>
                {!state.askEmail && 
                  <Button variant="contained" color="secondary" type="button" onClick={useAnotherEmail}>
                    {translate('wallet.login.useAnotherEmail')}
                  </Button>
                }
                <Button color="secondary" target="_new" type="button" id="forgotpasswordbutton" href={process.env.REACT_APP_GOV_APP_LINK+'?showpwreset=true'}>Forgot password?</Button>
              </Box>

            </Box>
          </form>

          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: '1em', color: '#BD2755', lineHeight: '1.3em' }}>
              { state.error && 
                <React.Fragment>
                  <ErrorIcon />
                  &nbsp;
                  {state.error}
                </React.Fragment>
              }
            </Grid>
          </Grid>

        </TMPaper>
      </div>
      { props.children }
    </div>
  )
}

const initialState = {
  askEmail: false,
  progress: false,
  userOrganizations: [],
  walletInfo: {},
  error: '',
  localStorageVars: {
    email: '',
    password: '',
    organization: '',
  }
}

const reducer = (state, { payload }) => {
  return { ...state, ...payload }
}

export default (SetLocalStorageVars);

