import React, { useReducer, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import reducer from '../../util/reducer';
import useIntl from '../../hooks/useIntl';
import { useProgressDialog } from '../../context/ProgressDialogProvider';
import { useAlertDialog } from '../../context/AlertDialogProvider';
import useSentry from '../../hooks/useSentry';
import TMDialog from '../../lib/Dialog/TMDialog';
import TMForm from '../../lib/Form/TMForm';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import client from '../../feathers';
import * as Subscriptions from '../../util/subscriptions';

const initialState = {
  reference: '',
  invoiceNumber: '',
  carrier: '',
  blNumber: '',
  containersRaw: "",
  containers: [],
  loi: 'If applicable, download the LOI template below and add it to your request, duly filled in and signed.',
  files: [],
  fileRefs: [],
  carriers: [],
};

const useStyles = makeStyles(() => ({
  icon_hover: {
    '&:hover': {
      color: '#cda24d',
    },
  },
}));

const RequestForReleaseDialog = ({
  open,
  handleDialogClose,
  user,
  fetchRequestsForRelease
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [openConfirm, setOpenConfirm] = useState(false);
  const classes = useStyles();

  const { translate } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { showAlert } = useAlertDialog();
  const logSentry = useSentry();

  // Define carrier validations
  const carrierValidations = {
    '': {},
    [process.env.REACT_APP_HPL_ADDRESS]: { mustStartWith: ['HLCU', 'hlcu'] }
  }

  // Define carrier LOI templates
  const carrierLoiTemplates = {
    '': [],
    [process.env.REACT_APP_HPL_ADDRESS]: ['hpl/Continuous_transfer_declaration_E-FORM_2022_Hapag.pdf', 'hpl/Transfer_declaration_Hapag.pdf']
  }

  // Prefill carriers
  useEffect(() => {
    const getCarriers = async() => {
      try {
        // TODO: check for active connection with carrier
        const rfrSubscription = user.subscriptions.find(s => s.subscriptionId === Subscriptions.SUBSCR_REQUEST_FOR_RELEASE);
        if (rfrSubscription) {
          // just in case the remark is NULL, prepend an empty string to it so 'split' won't throw an error
          const carrierIds = ("" + rfrSubscription.remark)
            .split(',')
            .map(id => {  
              try {
                return parseInt(id)
              } catch (error) {
                return ""
              }
            });

          if (carrierIds.every(carrierId => typeof carrierId === 'number')) {
            const carriers = await client.service('organizations').find({ query: { id: { $in: carrierIds } } });
            dispatch({ 
              payload: { 
                carriers: carriers.data.map(c => ({ 
                  value: c.address, 
                  label: c.name, 
                  target: { 
                    name: 'carrier', 
                    value: c.address 
                  } 
                })) 
              } 
            });
          }
        }
      } catch (error) {
        logSentry(error);
        showAlert(error.message);
      }
    }
    if (user.organizationRole !== 'carrier') {
      getCarriers();

    }
  }, []);

  useEffect(() => {
    const parseContainers = async () => {
      let containers = [];
      let buffer = '';

      for (var i = 0; i < state.containersRaw.length; i++) {
       let c = state.containersRaw[i];
       // Check whether we have a number or character
       if (c.match(/[a-z]/i) || c.match(/[0-9]/)) {
         // console.debug("1", c)
         buffer = buffer + c;
       }
       // Check whether we have a separator
       if (c.match(/[\-,;\t\r\n]/) && buffer.length > 0) {
         // console.debug("2", c)
         containers.push(buffer);
         buffer = '';
       }
       // Check whether we have a space
       // If the buffer is larger than 6, we treat the space as a separator, otherwise we ignore it
       if (c.match(/ /) && buffer.length > 6) {
         // console.debug("3", c)
         containers.push(buffer);
         buffer = '';
       }
      }
      // Push any remaining buffer content into the containers array
      if (buffer.length > 0) {
       containers.push(buffer);
      }

      // containernummers hebben een prefix van max vier letters, bl's hebben een prefix die langer is
      dispatch({ payload: { containers } });
    };

    parseContainers();
  }, [state.containersRaw]);

  const resetForm = () => dispatch({ payload: {
    reference: '',
    invoiceNumber: '',
    carrier: '',
    blNumber: '',
    containersRaw: "",
    containers: [],
    files: [],
    fileRefs: [],
  }});

  const resetAndClose = () => {
    window.addToDataLayer('request-for-release', 'close');
    setOpenConfirm(false);
    handleDialogClose(resetForm);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ payload: { [name]: value } });
  };

  const handleFileAdd = (newFiles) => {
    let files = [...state.files];
    files.push(
      ...newFiles.map(f => {
        let filename = f.file.name;
        let dupFile = {};
        let dupIndex = 0;
        while (dupFile !== undefined) {
          dupFile = files.find(df => df.name === filename);
          if (dupFile) {
            const sepChar = dupIndex === 0 ? '.' : '-';
            filename = `${filename.substring(0, filename.lastIndexOf(sepChar))}-${++dupIndex}.${filename.substring(filename.lastIndexOf('.')+1)}`;
          }
        }
        return { name: filename, ref: undefined, content: f.data } 
      })
    );
    dispatch({ payload: { files } });
  };

  const handleFileDelete = (deletedFileName) => {
    let files = [...state.files];
    dispatch({ payload: { files: files.filter(f => f.name !== deletedFileName) } });
  }

  const handleFileRefs = (fileName, e) => {
    const files = [...state.files];
    let file = files.find(f => f.name === fileName)
    file.ref = e.target.value;
    dispatch({ payload: { files } });
  }

  const handleRequestForRelease = async () => {
    try {
      showProgressDialog();
      // save entry in db
      await client.service('requests-for-release').create({
        reference: state.reference,
        invoiceNumber: state.invoiceNumber,
        carrierAddress: state.carrier,
        blNumber: state.blNumber,
        containersRaw: state.containersRaw,
        containers: state.containers,
        files: state.files,
        organizationAddress: user.organizationAddress,
        createdAt: new Date(),
      })
      enqueueSnackbar(translate("req.for.release.created"), { autoHideDuration: 3000 });
      fetchRequestsForRelease && await fetchRequestsForRelease();
      resetAndClose();
    } catch (error) {
      logSentry(error);
      showAlert(error.message);
    } finally {
      hideProgressDialog();
    }
  }

  return (
    <React.Fragment>
      <TMDialog
        key="request-for-release"
        title={translate("req.for.release.title")}
        dialogOpen={open}
        handleDialogClose={resetAndClose}
        showBottomClose={false}
        maxWidth="lg"
      >
        <TMForm
          object={state}
          handleChange={handleChange}
          handleFileAdd={handleFileAdd}
          handleFileDelete={handleFileDelete}
          handleSubmit={handleRequestForRelease}
          submitLabel={translate('req.for.release.submit')}
          enableSubmitCondition={!!state.blNumber && !!state.carrier}
          handleCancel={resetAndClose}
          cancelLabel={translate('general.cancel')}
          fields={[
            { field: 'reference', label: translate("req.for.release.reference.label"), fullWidth: true },
            { field: 'invoiceNumber', label: translate("req.for.release.invoiceNumber.label"), fullWidth: true },
            { field: 'carrier', label: translate("req.for.release.carrier.label"), required: true, componentType: Select, suggestions: state.carriers, fullWidth: true },
            (state.carrier !== '' && carrierLoiTemplates[state.carrier].length > 0) ?
              { field: 'loi', label: translate("req.for.release.loi.label"), componentType: Typography, fullWidth: true, 
                helperText: carrierLoiTemplates[state.carrier].map(loi => <span><a href={`/assets/loi/${loi}`} target='_new'>{ loi }</a><br/></span>)
              } : {},
            { field: 'blNumber', label: translate("req.for.release.bl.label"), required: true, fullWidth: true, ...carrierValidations[state.carrier] },
            { field: 'containersRaw', label: translate("req.for.release.list.label"), rows: 4, multiline: true, fullWidth: true, helperText: translate("req.for.release.list.helper") },
            { field: 'files', label: translate("req.for.release.files.label"), componentType: DropzoneAreaBase, fullWidth: true, 
              helperText: 'When uploading files with the same name, a unique suffix will be added automatically' },
          ]}
        >
        { state.files.length > 0 &&
          <Grid container spacing={2} style={{marginBottom: '1em'}}>
            <Grid item xs={12} sm={2} style={{ paddingTop: '1em' }}>
              File info
            </Grid>
            <Grid item xs={12} sm={10}>
              { state.files.map((file, index) => (
                <Grid container spacing={2} key={`grid-${index}`}>
                  <Grid item sm={5} style={{paddingTop: '1.5em'}}>
                    <IconButton onClick={() => { handleFileDelete(file.name) }} className={classes.icon_hover}>
                      <DeleteIcon />
                    </IconButton>
                    {file.name}
                  </Grid>
                  <Grid item sm={7} style={{paddingTop: '1.2em'}}>
                    <TextField size="small" placeholder='Add file info or comment' fullWidth onChange={(e) => handleFileRefs(file.name, e)} fullWidth/>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        } 
        </TMForm>
      </TMDialog>
    </React.Fragment>
  );
};

export default RequestForReleaseDialog;
