import _ from 'lodash';

export const filterContainers = ({
  uploadedContainers,
  existingContainers,
  ownerAddresses,
}) => {
  // Filter out the releases that are not going to be processed
  let notFound = uploadedContainers.filter(
    ({ blNumber, containerNumber }) =>
      !existingContainers.find(
        (container) =>
          container.release.blNumber === blNumber &&
          container.containerNumber === containerNumber
      )
  );

  let conditions = {
    owned: (release) => ownerAddresses.includes(release.owner),
    valid: (release) => release.releaseStatus !== 'invalid',
    transferred: (release) => release.transferStatus === 'transferred',
    pincodeFetched: (release) => release.pincode_logs?.length || 0,
    pickedUp: (release) => release.releaseStatus === 'gateout',
  };

  let owned = existingContainers;

  if (ownerAddresses.length) {
    owned = existingContainers.filter(({ release }) =>
      conditions['owned'](release)
    );
  }

  let eligible = [...owned];

  // Check invalid
  const invalid = eligible.filter(
    ({ release }) => !conditions['valid'](release)
  );
  eligible = _.difference(eligible, invalid);

  // Check transferred
  const transferred = eligible.filter(({ release }) =>
    conditions['transferred'](release)
  );
  eligible = _.difference(eligible, transferred);

  // Check pinRetrieved
  const pincodeFetched = eligible.filter(({ release }) =>
    conditions['pincodeFetched'](release)
  );
  // SCRDEV-192: not necessary, this is not a blocking error, just a warning
  //eligible = _.difference(eligible, pincodeFetched);

  // Check Picked Up
  const pickedUp = eligible.filter(({ release }) =>
    conditions['pickedUp'](release)
  );
  eligible = _.difference(eligible, pickedUp);

  notFound = [
    ...new Set(
      notFound.concat(
        _.difference(existingContainers, owned).map((container) => ({
          blNumber: container.release.blNumber,
          containerNumber: container.containerNumber,
        }))
      )
    ),
  ];

  return { notFound, pincodeFetched, eligible, invalid, transferred, pickedUp };
};

export const createError = ({
  notFound,
  transferred,
  invalid,
  pickedUp
}) => {
  // can't return directly, in case 'notFound' is empty nothing gets returned.
  // save it in a variable and return the variable for great success :-)
  let result = 
    notFound
    .map(c => ({
      bl: c.blNumber, 
      ctr: c.containerNumber, 
      msg: `${c.blNumber} : ${c.containerNumber} was not found or is not owned by you`
    }))
    .concat(transferred.map(c => ({
      bl: c.release.blNumber,
      ctr: c.containerNumber,
      msg: `${c.release.blNumber} : ${c.containerNumber} was already transferred to the next party`
    })))
    .concat(invalid.map(c => ({
      bl: c.release.blNumber,
      ctr: c.containerNumber,
      msg: `${c.release.blNumber} : ${c.containerNumber} has expired ("valid until"-date is in the past)`
    })))
    .concat(pickedUp.map(c => ({
      bl: c.release.blNumber,
      ctr: c.containerNumber,
      msg: `${c.release.blNumber} : ${c.containerNumber} was already picked up`
    })))
  return result;
};

export const createWarning = ({ 
  eligible, 
  pincodeFetched
}) => {
  return eligible
    .filter((bl) => bl.release.blocked)
    .map(c => ({
      bl: c.blNumber,
      ctr: c.containerNumber,
      msg: `${c.blNumber} : ${c.containerNumber} cannot retrieve pin since it's blocked by the carrier (you can transfer it to the next party)`
    }))
    .concat(pincodeFetched.map(c => ({
      bl: c.release.blNumber,
      ctr: c.containerNumber,
      msg: `${c.release.blNumber} : ${c.containerNumber} cannot be transferred since the pin was already retrieved `
    })))
};

export const split = (data) =>
  data.reduce(
    (acc, c) => {
      acc[0].push(c.blNumber?.toString());
      acc[1].push(c.containerNumber?.toString());

      return acc;
    },
    [[], []]
  );

export const calculateTotal = ({
  notFound,
  pincodeFetched,
  transferred,
  pickedUp,
  invalid,
  eligible
}) => {
  return [notFound, pincodeFetched, transferred, pickedUp, invalid, eligible.filter((bl) => bl.release.releaseStatus === 'blocked')].reduce(
    (acc, c) => (acc += c.length),
    0
  );
}

export const mapForExcel = (data) => {
  return data.map(
    ({
      blNumber,
      containerNumber,
      pickupLocation,
      turnInLocation,
      turnInReference,
      updatedAt,
      validFrom,
      validUntil,
      release: { address, releaseStatus, transferStatus, owner },
    }) => {
      return {
        blNumber,
        releases: [
          {
            address,
            releaseStatus,
            transferStatus,
            owner,
            container: {
              containerNumber,
              pickupLocation,
              turnInLocation,
              turnInReference,
              updatedAt,
              validFrom,
              validUntil
            },
          },
        ],
      };
    }
  );
};
