import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import WalletAuthenticationClient from './authentication/WalletAuthenticationClient';
// Socket.io is exposed as the `io` global.
const socket = io(process.env.REACT_APP_BACKEND_URL_SOCKETIO);
// feathersjs/client is exposed as the `feathers` global.

const auth = require('@feathersjs/authentication-client');

class Feathers {

	constructor() {
		this.init();
		this.io = socket;
	}

	init = () => {
		if (window.feathersClient === undefined) {
			const app = feathers();

			window.feathersClient = app
				.configure(
			    feathers.socketio(socket, {
			      timeout: 10 * 60 * 1000,
			    })
		  	)
	  		.configure(
			    auth({
			      storage: window.sessionStorage,
			      storageKey: 'jwt-accessToken',
			      Authentication: WalletAuthenticationClient,
			    })
	  		);
		} else {
			console.log("ignore feathers init");
		}
	}

	service = (name) => {
		return window.feathersClient.service(name);
	}

	on = (event, handler) => {
		return window.feathersClient.on(event, handler);
	}

	authenticate = (...args) => {
		return window.feathersClient.authenticate(...args);
	}

        authentication = () => {
		return window.feathersClient.authentication;
	}
}


const client = new Feathers();

export default new Feathers();
