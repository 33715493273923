import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Icon from '@mdi/react'
import { mdiContentCopy as CopyIcon, 
         mdiEmailSyncOutline as ResendLink } from '@mdi/js';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TMDateInput from '../Input/TMDateInput';
import TMDataTable from '../Table/TMDataTable';
import logSentry from '../../util/logSentry';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import AttachFile from '@material-ui/icons/AttachFile';

const classes = theme => ({
  root: {
    flexGrow: 1,
  },
  formRow: {
    display: 'flex', 
    alignItems: 'top', 
    marginBottom: '1em'
  },
  label: {
    display: 'flex',
    marginTop: '1em',
    fontFamily: 'Roboto,"Helvetica Neue",Arial,sans-serif',
  },
  secondaryLabel: {
    display: 'flex',
    marginTop: '0.7em',
    fontFamily: 'Roboto,"Helvetica Neue",Arial,sans-serif',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '0.9em',
    fontWeight: 'bold'
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginTop: '1em'
  },
  radio: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  dateField: {
    width: `${theme.spacing(1.4)}em`, // '11em'
  },
  timeField: {
    width: `${theme.spacing(1.1)}em`, // '7em'
  },
  hidden: {
    display: 'none'
  },
  button: {
    marginRight: theme.spacing(2)
  },
  tooltip: {
    cursor: 'pointer'
  },
  tooltext: {
    fontSize: '1.8em',
    lineHeight: '1.5em'
  },
  readOnly: {
    padding: theme.spacing(2),
    '& input, div[class*="multiline"]': {
      backgroundColor: theme.palette.secondary.light
    }
  },
  link: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.light,
    padding: '0.75em',
  },
  smallText: {
    color: theme.palette.secondary.dark,
    display: 'block'
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
  copyInput:{
    borderStyle: 'none',
    backgroundColor: 'transparent',
    width: '90%',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    textOverflow: 'ellipsis',
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
});

class TMForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSubmitDisabled: false,
      showBackdrop: false,
      showPassword: false,
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  };
    
  handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  hideBackdrop = () => {
    this.setState({ showBackdrop: false })
  };

  submitForm = async () => {
    this.setState({
      isSubmitDisabled: true,
      showBackdrop: true,
    }, () => {
      this.props.handleSubmit()
      .then(() => {
        this.setState({ 
          isSubmitDisabled: false,
          showBackdrop: false
        })
      })
    })
  };

  componentDidMount = () => {
    let { object } = this.props;
    if (object.hasOwnProperty('password') && object.hasOwnProperty('passwordCheck')) {
      ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        return this.isPasswordMatch();
      })
    }
  };

  componentWillUnmount = () => {
    ValidatorForm.removeValidationRule('isPasswordMatch');
  };

  isPasswordMatch = () => {
    let { object } = this.props;
    return object.password === object.passwordCheck
  };

  render() {
    
    let { classes, object, fields, readOnly=false, variant='outlined',
          handleChange, handleCancel, handleSkip, submitLabel, handleFileAdd, handleFileDelete,
          cancelLabel, cancelButtonVariant="outlined", skipLabel, enableSubmitCondition=true, 
          children, excelLabel, handleExcel, enableExcelCondition=true } = this.props;

    let { isSubmitDisabled, showBackdrop } = this.state;

    // sometimes fields are skipped depending on the wallet type, so filter them out here first
    let nonEmptyFields = fields.filter(item => item.hasOwnProperty('field'));

    return (
      <ValidatorForm
        id={this.props.id}
        ref="form" 
        className={classNames({[classes.readOnly]: readOnly})}
        onSubmit={this.submitForm}
        instantValidate={false}
        onError={errors => logSentry(errors.map(err => err.getErrorMessage()))}
      >
        <Grid container spacing={2} className={classes.formRow}>
        {
          nonEmptyFields.map((item, idx) => {
            let validators = item.validators === undefined ? [] : item.validators;
            let errorMessages = item.errorMessages === undefined ? [] : item.errorMessages;
            
            if (item.required) {
              validators.push('required');
              let article = (/^[aeiouy]/).test(item.label.toLowerCase()) ? 'an' : 'a';
              errorMessages.push(`Please provide ${article} ${item.label.toLowerCase()}`);
            }
            
            if (item.isEmail) {
              validators.push('isEmail');
              errorMessages.push('Email is not valid');
              item.type = 'email';
            }

            if (!item.hasOwnProperty('type')) {
              item.type = 'text';
            }

            let inputProps = {};
            if (item.prefix !== undefined) {
              inputProps.startAdornment = (<InputAdornment position="start">{item.prefix}</InputAdornment>);
            }
            if (item.suffix !== undefined) {
              inputProps.endAdornment = (<InputAdornment position="end">{item.suffix}</InputAdornment>);
            }

            const fieldValue = Array.isArray(object) ? '' : object[item.field];
            let attributes = { 
              name: item.field,
              id: item.field,
              value: Array.isArray(fieldValue) ? (fieldValue[item.index] && fieldValue[item.index].hasOwnProperty('value') ? fieldValue[item.index]['value'] : '' ): fieldValue, 
              type: item.type,
              placeholder: item.placeholder ? item.placeholder : (item.disabled ? '' : item.label), 
              minrows: item.rows,
              multiline: item.multiline,
              autoFocus: idx === 0, 
              onChange: item.handleChange ? item.handleChange : handleChange, // an item can have its custom handleChange method. If not, use the general method, passed in the props. 
              onBlur: item.onBlur, 
              onClick: item.onClick, 
              margin: 'none',
              variant: variant, 
              readOnly: readOnly, 
              validators: validators,
              suggestions: item.suggestions ? item.suggestions : [],
              disabled: item.disabled,
              color: item.color ? item.color : 'primary',
              helperText: item.helperText, 
              fullWidth: true,
              errorMessages: errorMessages,
              InputProps: inputProps,
            }

            const alfanumType = item.type === 'number' ? 'digits' : 'characters';
            if (item.minLength !== undefined && Number.isInteger(item.minLength)) {
              if (item.maxLength !== undefined && Number.isInteger(item.maxLength)) {
                if (item.minLength === item.maxLength) {
                  validators.push('matchRegexp:^.{'+item.minLength+'}$');
                  errorMessages.push(
                    `Please enter ${item.minLength} ${alfanumType}`
                  );
                } else {
                  validators.push('matchRegexp:^.{'+item.minLength+','+item.maxLength+'}$');
                  errorMessages.push(
                    `Please enter at least ${item.minLength} and no more than ${item.maxLength} ${alfanumType}`
                  );
                }
              } else {
                validators.push('matchRegexp:^.{'+item.minLength+',}$');
                errorMessages.push(`Please enter at least ${item.minLength} ${alfanumType}`)
              }
            } else {
              if (item.maxLength !== undefined && Number.isInteger(item.maxLength)) {
                validators.push('matchRegexp:^.{,'+item.maxLength+'}$');
                errorMessages.push(`Please enter no more than ${item.maxLength} ${alfanumType}`)
              }
            }

            if (item.mustStartWith !== undefined) {
              validators.push('matchRegexp:^('+item.mustStartWith.join('|')+').*');
              errorMessages.push(
                `${item.label} must start with one of: ${item.mustStartWith.join(', ')}`
              );
            }

            let children = null;
            let ComponentType = item.componentType;

            // override 1
            if (ComponentType && ComponentType === Switch) {
              // too many attributes cause errors, so be selective here
              attributes = { 
                name: item.field,
                value: [undefined, null].includes(fieldValue) ? "" : fieldValue.toString(), 
                checked: !!fieldValue, // in case fieldValue undefined
                onChange: handleChange, 
                color: 'primary',
                disabled: item.disabled,
              }
            }
            
            // override 3
            if (ComponentType && ComponentType === Select) {

              delete attributes.helperText
              delete attributes.errorMessages
              delete attributes.InputProps
              attributes.labelId = attributes.id
              attributes.displayEmpty = true
              children = item.suggestions.map(s => <MenuItem key={s.value} value={s.value}>{s.label}</MenuItem>)
              children.unshift(<MenuItem key='empty' disabled>{attributes.placeholder}</MenuItem>)
            }

            // override 4
            if (ComponentType && ComponentType === TMDateInput) {
              delete attributes.errorMessages
            }

            // override 5
            if (ComponentType && ComponentType === Button) {
              attributes = {
                variant: item.variant,
                href: item.href,
                target: item.target ? item.target : '_self',
                onClick: item.onClick,
                color: item.color ? item.color : 'primary',
                disableElevation: true,
              }
              children = item.btnLabel ? item.btnLabel : item.href
            }

            // override 6
            if (item.type === 'file') {
              attributes.accept = item.accept;
            }

            // override 7
            if (item.type === 'password') {
              attributes.type = this.state.showPassword ? 'text' : 'password';
              attributes.InputProps = {
                endAdornment: 
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }
            }

            // override 8
            if (item.field === '_divider') {
              ComponentType = Divider;
              item.fullWidth = true;
              attributes = {
                className: classes.divider
              };
            }

            // override 9
            let isFullWidthTable = false;
            if (ComponentType && ComponentType === TMDataTable) {
              attributes = {
                data: item.data,
                columns: item.columns,
                options: item.options,
                title: item.title,
              };
              // if there is no separate label, but there is a title, we can ditch the label column
              // and take the entire width for the table
              if (item.label === undefined && item.title !== undefined) {
                isFullWidthTable = true;
              }
            }

            // override 10
            if (ComponentType && ComponentType === DropzoneAreaBase) {
              attributes = {
                style: {minHeight: '160px'},
                onAdd: handleFileAdd, 
                onDelete: handleFileDelete, 
                /*
                showPreviews: true,
                showPreviewsInDropzone: false,
                showAlerts: false,
                useChipsForPreview: true,
                previewGridProps: {container: { spacing: 1, direction: 'row' }},
                previewChipProps: {classes: { root: classes.previewChip }},
                previewText: "Selected files",
                */
                filesLimit: 10,
              }
            }

            return (
              <React.Fragment key={idx}>
                { !isFullWidthTable &&
                  <Grid item xs={12} sm={2} className={item.field === '_divider' ? classes.secondaryLabel : classes.label}>
                    {item.label} {item.required && '*'}
                  </Grid>
                }
                <Grid item xs={12} sm={isFullWidthTable ? 12 : (item.fullWidth === true || nonEmptyFields.length === 1 ? 10 : 4)}>
                  {
                    (ComponentType && [Switch, Checkbox].includes(ComponentType)) &&
                      <FormGroup row>
                        <FormControlLabel
                          control={ <ComponentType color="primary" checked={!!fieldValue} value={(fieldValue).toString()} onChange={handleChange} name={item.field} /> }
                          label={item.placeholder || item.helperText}
                          style={{fontSize: '0.9em'}}
                        />
                      </FormGroup>
                  }
                  {
                    (ComponentType && ComponentType === Typography) &&
                      <React.Fragment>
                        <Typography variant={item.variant} id={item.field} className={classes.link} display="block">
                          <input type="text" id={`copy-${item.field}`} className={classes.copyInput} value={fieldValue} readOnly={true}/>
                          { item.resendLink &&
                            <Icon title="Click to resend the link via email" path={ResendLink} size={1} color='grey' style={{float: 'right', cursor: 'pointer'}} onClick={() => item.resendLink(item.field)}/>
                          }
                          { item.copyLink &&
                            <Icon id="copyLink" title="Click to copy the link to your clipboard" path={CopyIcon} size={1} color='grey' 
                              style={{float: 'right', cursor: 'pointer', marginRight: '5px'}} 
                              onClick={() => item.copyLink(`copy-${item.field}`)}
                            />
                          }
                        </Typography>
                        <small className={classes.smallText}>{item.helperText}</small>
                      </React.Fragment>
                  }
                  <React.Fragment> 
                  {
                    (!ComponentType || ![Checkbox, Typography, Switch].includes(ComponentType)) && item.field !== '_filler' && 
                      React.createElement(
                        ComponentType ? ComponentType : TextValidator, 
                        attributes,
                        children
                      )
                  }
                  {
                    (ComponentType && [Button, Divider, Switch, TMDataTable, DropzoneAreaBase].includes(ComponentType) && item.helperText) &&
                      <small className={classNames(classes.smallText, {[classes.primaryText]: ComponentType === Divider})} style={{marginTop: '5px'}}>{item.helperText}</small>
                  }
                  </React.Fragment>
                </Grid>
              </React.Fragment>
            )
          })
        }
        </Grid>
        <Grid container spacing={2}>{children}</Grid>
        <Grid container spacing={2}>
          <Grid item xs={2} className={classes.label}></Grid>
          <Grid item xs={10}>
            {
              submitLabel && <Button type="submit" className={classes.button} variant="contained" size="large" disabled={isSubmitDisabled || !enableSubmitCondition} color="primary">{submitLabel}</Button>
            }
            {
              skipLabel && <Button onClick={handleSkip} className={classes.button} variant="outlined" size="large" color="primary">{skipLabel}</Button>
            }
            {
              excelLabel && <Button onClick={handleExcel} className={classes.button} variant="outlined" size="large" disabled={!enableExcelCondition} color="primary">{excelLabel}</Button>
            }
            {
              cancelLabel && <Button onClick={handleCancel} className={classes.button} variant={cancelButtonVariant} size="large" color="secondary">{cancelLabel}</Button>
            }
          </Grid>
        </Grid>

        <Backdrop 
          open={showBackdrop} 
          // otherwise the backdrop is stuck in the bottom part of the screen ('88vh' defined in App.js)
          style={{position: 'absolute', zIndex: showBackdrop ? 10000 : -1}} 
          onClick={this.hideBackdrop}
        />

      </ValidatorForm>
    );
  }
}

TMForm.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.oneOfType([PropTypes.array,PropTypes.object]).isRequired,
  fields: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array,PropTypes.object])).isRequired,
  handleChange: PropTypes.func,
  handleSelect: PropTypes.func,
};

export default withStyles(classes)(TMForm);
