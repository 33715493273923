import React, { useState } from 'react';
import client from '../feathers';

import { Box } from '@material-ui/core';
import { formatDate } from '../util/date'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TMDataTable from '../lib/Table/TMDataTable';
import TMDialog from '../lib/Dialog/TMDialog';
import useIntl from './useIntl';

export const useSuccessRateOfSecurePickup = (type) => {
    console.log("useSuccessRateOfSecurePickup", type)

    const [state, setState] = useState({});
    const [openSrsPickup, setOpenSrsPickup] = useState(false);
    const [srsQuery, setSrsQuery] = useState('1 week');

    const { translate } = useIntl();

    const fetchSrsPickup = async (queryParams = { operationAt: '1 week' }) => {
        const { overview, checkFailed } = await client
            .service(type)
            .find({
                query: {
                    successRateOfSecurePickupReport: true,
                    type,
                    ...queryParams,
                },
            });

        setState(() => ({ successRateOfSecurePickupReport: { overview, checkFailed } }))

    };

    const openSrsPickupDialog = () => {
        fetchSrsPickup();
        setOpenSrsPickup(true);
    };

    const closeSrsPickupDialog = () => setOpenSrsPickup(false);

    const handleSrcDateFilter = (e) => {
        const value = e.target.value;
        const operationAt = value == 'all' ? null : value;
        fetchSrsPickup({ operationAt });
        setSrsQuery(value)
    };

    const successRateOfSecurePickupJsx = <TMDialog
        key="success-rate-of-secure-pickup"
        title={translate(`transporters.assign.report.title`)}
        dialogOpen={openSrsPickup}
        handleDialogClose={closeSrsPickupDialog}
        maxWidth="md"
        showBottomClose={false}
    >
        <Box sx={{ mb: 2 }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Select
                style={{ minWidth: '120' }}
                value={srsQuery}
                onChange={handleSrcDateFilter}
            >
                <MenuItem value="1 week">{translate(`transporters.assign.report.1week`)}</MenuItem>
                <MenuItem value="1 month">{translate(`transporters.assign.report.1month`)}</MenuItem>
                <MenuItem value="all">{translate(`transporters.assign.report.all`)}</MenuItem>
            </Select>
        </Box>
        <Box sx={{ mb: 2 }}>
            <TMDataTable
                className='pl-16 pr-16'
                title={translate(`transporters.assign.report.overview`)}
                columns={[
                    { name: 'description', label: translate(`transporters.assign.report.description`) },
                    { name: 'count', label: translate(`transporters.assign.report.count`) },
                ]}
                data={
                    state.successRateOfSecurePickupReport
                        ? state.successRateOfSecurePickupReport.overview
                        : []
                }
                options={{
                    print: false,
                    filter: false,
                    viewColumns: false,
                    pagination: false,
                    download: false,
                    search: false,
                }}
            />
        </Box>
        <Box sx={{ mb: 2 }}>
            <TMDataTable
                className='pl-16 pr-16'
                title={translate(`${type}.assign.report.checkFailed`)}
                columns={[
                    {
                        name: 'check_at',
                        label: translate(`transporters.assign.report.checkAt`),
                        options: {
                            customBodyRender: (check_at) => formatDate(check_at),
                        },
                    },
                    { name: 'container_number', label: translate('transporters.assign.report.containerNumber') },
                    { name: 'assigned_value', label: translate(`${type}.assign.report.assignedValue`) },
                    { name: 'checked_value', label: translate(`${type}.assign.report.checkedValue`) },
                    { name: 'reason', label: translate(`transporters.assign.report.reason`) },
                ]}
                data={
                    state.successRateOfSecurePickupReport
                        ? state.successRateOfSecurePickupReport.checkFailed
                        : []
                }
                options={{
                    print: false,
                    filter: false,
                    viewColumns: false,
                    textLabels: {
                        pagination: {
                            rowsPerPage: translate(`transporters.assign.report.rowsPerPage`),
                        },
                    },
                }}
            />
        </Box>
    </TMDialog>;

    return { successRateOfSecurePickupJsx, openSrsPickupDialog }
};