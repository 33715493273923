import { Http } from '../../axios';

export const assignReleases = async ({ type, identification, releaseAddresses, visitNumber, anonAddresses }) => {
  return await Http.update('releases/assignment/assign', {
    type,
    identification,
    releaseAddresses,
    visitNumber,
    includeadr: anonAddresses
  })
};

export const unAssignReleases = async ({ releaseAddresses, anonAddresses }) => {
  return await Http.update('releases/assignment/unassign', {
    releaseAddresses,
    includeadr: anonAddresses
  })
};

export const checkIsReleaseAssigned = async ({ releaseAddresses, anonAddresses }) => {
  return await Http.update('releases/assignment/info', {
    releaseAddresses,
    includeadr: anonAddresses
  })
};

export const checkAssignLocations = async ({ releaseAddresses, anonAddresses }) => {
  return await Http.update('releases/assignment/check', {
    releaseAddresses,
    includeadr: anonAddresses
  })
};

export const getReleaseAuditTrail = async ({ releaseAddresses, anonAddresses }) => {
  return await Http.post('audit-trail', {
    releaseAddresses,
    includeadr: anonAddresses
  });
};
