import React, { useReducer } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch as RouterSwitch,
} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import TMAppBar from '../lib/AppBar/TMAppBar';
import { ROUTES } from './routes';
import useAuth from '../hooks/useAuth';
import SetLocalStorageVars from '../components/SetLocalStorageVars';
import { isTokenValid } from '../util/token';
import useDrawer from '../hooks/useDrawer';
import TMDialog from '../lib/Dialog/TMDialog';
import reducer from '../util/reducer';
import useIntl from '../hooks/useIntl';
import SRPForm from '../components/SRPForm';
import Switch from '@material-ui/core/Switch';
import client from '../feathers';
import GitInfo from 'react-git-info/macro';

import { useCookies } from 'react-cookie';
import CookieBanner from '../components/CookieBanner';

const initialState = {
  openEula: true,
  eulaAccepted: false,
  latestEulaId: undefined,
};

const drawerWidth = '30%';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      fontFamily: 'Roboto',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      height: '12vh',
    },
    appBarShift: {
      width: `calc(100vw - ${drawerWidth})`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: 0,
      overflow: 'auto',
      height: '88vh',
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    gitInfo: {
      position: 'absolute',
      bottom: '10px',
      left: '10px',
      fontSize: '10px',
      padding: '4px',
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.light,
    },
  })
);

const Layout = () => {
  const classes = useStyles();
  const { accessToken, user } = useAuth();
  const { showDrawer, toggleDrawer } = useDrawer();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { translate } = useIntl();
  const gitInfo = GitInfo();

  const [cookies] = useCookies(['cookieAccepted']);

  const showCookieBanner = cookies.cookieAccepted === undefined;

  const helpClicked = () => toggleDrawer();

  const handleChange = (e) => {
    dispatch({
      payload: {
        [e.target.name]: e.target.checked,
        latestEulaId: user.eulaToSign.id,
      },
    });
  };

  const handleAddSavedEula = async () => {
    const result = await client
      .service('users')
      .patch(user.id, { eulaId: state.latestEulaId });
    window.location.reload();
  };

  const logout = () => {
    dispatch({ payload: { openEula: false } });
    window.addToDataLayer('eula-not-accepted', 'logout');
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };

  const getGitInfo = () => {
    return ["production", "uat", "cmacgm", "hpl"].includes(process.env.REACT_APP_NODE_ENV)
      ? ( <React.Fragment /> ) 
      : ( <div className={classes.gitInfo}>{gitInfo.branch} ({gitInfo.commit.shortHash}: {gitInfo.commit.message})</div>)
  };

  if (!isTokenValid()) {
    return <SetLocalStorageVars>
      {showCookieBanner && <CookieBanner position='right' />}
      {getGitInfo()}
      </SetLocalStorageVars>;
  }

  // SCRDEV-907 Check here whether user has to sign a new EULA
  if (user && user.hasOwnProperty('eulaToSign')) {
    return (
      <TMDialog
        key="sign-eula"
        title={translate('login.accept.eula')}
        dialogOpen={state.openEula}
        handleDialogClose={logout}
        maxWidth="sm"
        showBottomClose={false}
      >
        <p>
          {translate('eula.intro.1')}
          <br />
          {translate('eula.intro.2')}
        </p>
        <SRPForm
          object={state}
          handleChange={handleChange}
          handleSubmit={handleAddSavedEula}
          submitLabel={translate('login.accept.eula.submit')}
          handleCancel={logout}
          cancelLabel={translate('general.cancel')}
          enableSubmitCondition={state.eulaAccepted}
          fields={[
            {
              field: 'eulaAccepted',
              label: translate('order.premium.label.eula'),
              componentType: Switch,
              fullWidth: true,
              required: true,
              placeholder: translate('order.premium.label.eula.accept'),
              helperText: (
                <a href={user.eulaToSign.url} target="_new">
                  {translate('order.premium.label.eula.read')}
                </a>
              ),
            },
          ]}
        />
        {getGitInfo()}
        {showCookieBanner && <CookieBanner />}
      </TMDialog>
    );
  }

  return (
    <div className={classes.container}>
      <Router>
        <TMAppBar
          user={user}
          isAuthenticated={!!accessToken}
          handleHelpClick={helpClicked}
          className={clsx(classes.appBar, showDrawer && classes.appBarShift)}
          logoPath="../tmining-logo-mix.png"
        />
        <div
          className={clsx(classes.content, showDrawer && classes.contentShift)}
        >
          {showCookieBanner && <CookieBanner />}
          <RouterSwitch>
            {ROUTES.map(({ path, component, isProtected }) => (
              <ProtectedRoute
                key={path}
                path={`/${path}`}
                component={component}
                isProtected={isProtected}
                exact
              />
            ))}
          </RouterSwitch>
          {getGitInfo()}
        </div>
      </Router>
    </div>
  );
};

export default Layout;
